<template>
  <a-spin :spinning="loading">
    <div>
      <div class="row">
        <div class="col-md-6">
          <a-form-item label="Region">
            <a-select
              :filter-option="filterOptions"
              :disabled="!getGeoDataStates.length || !isOnboarding"
              show-search
              option-filter-prop="name"
              @change="getStateCities"
              v-decorator="[
                'address.state',
                {
                  rules: [{ required: true, message: 'Region is required!' }],
                },
              ]"
              placeholder="Please select your state"
            >
              <a-select-option
                :key="index"
                v-for="(states, index) in getGeoDataStates"
                :value="states.geonameId"
              >
                {{ states.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>

        <div class="col-md-6">
          <a-form-item label="District">
            <a-select
              :disabled="!getGeoDataCities.length || !isOnboarding"
              :filter-option="filterOptions"
              option-filter-prop="name"
              show-search
              v-decorator="[
                'address.city',
                {
                  rules: [{ required: true, message: 'District is required!' }],
                },
              ]"
              placeholder="Please select your district"
            >
              <a-select-option
                :key="index"
                v-for="(cities, index) in getGeoDataCities"
                :value="cities.geonameId"
              >
                {{ cities.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <a-form-item
            label="GOOGLE MAP ADDRESS "
            v-if="showGoogle"
            :required="true"
          >
            <p class="text-warning">
              Type your location in the field below or pick it from the map
              using the marker.
            </p>

            <div style="width: 50%; margin-bottom: 10px">
              <a-auto-complete
                v-model="locationStr"
                :data-source="locationsDataSource"
                placeholder="Start typing your business location."
                option-label-prop="value"
                @select="onSelect"
                @search="onSearchLocation"
              >
                <template slot="dataSource">
                  <a-select-option
                    v-for="loc in locationsDataSource"
                    :key="loc.name"
                  >
                    <span>{{ loc.name }}</span>
                  </a-select-option>
                </template>
              </a-auto-complete>
            </div>

            <a-spin :spinning="mapLoading">
              <div id="map" class="map-display"></div>
            </a-spin>
          </a-form-item>

          <div v-if="!isOnboarding" id="map" class=""></div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MISC_GET_GEO_DATA } from "@/store/actions";

export default {
  props: {
    showGoogle: {
      type: Boolean,
      default: false,
    },
    isOnboarding: {
      type: Boolean,
      default: false,
    },
    locationData: {
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      mapLoading: false,

      googleMapsAPIKey: "AIzaSyBfAvKSu2qwl8KxOtjEliqUK888chwQVBs",
      locationsDataSource: [],
      locationStr: "",
      map: null,
      marker: null,
      infoWindow: null,

      country: {
        geo_id: 2300660,
        latitude: 7.946527,
        longitude: -1.023194,
        name: "Ghana",
        iso_code: "GH",
      },
    };
  },
  computed: {
    ...mapGetters("misc", ["getGeoDataStates", "getGeoDataCities"]),
    allowedCountry() {
      return this.country.iso_code;
    },
  },
  mounted() {
    if (this.isOnboarding) {
      this.initMap();
    } else if (this.locationData) {
      this.initMapForFacilityLocation();
    }

    const ghanaGeoNameId = 2300660;
    this.getCountryStates(ghanaGeoNameId);
  },
  methods: {
    ...mapActions("misc", {
      getGeoData: MISC_GET_GEO_DATA,
    }),
    initMap() {
      const mapEl = document.getElementById("map");
      mapEl.classList.add("map-display");

      const LatLngGhana = new google.maps.LatLng(
        this.country.latitude,
        this.country.longitude
      );

      this.map = new google.maps.Map(mapEl, {
        center: LatLngGhana,
        zoom: 10,
      });

      this.marker = new google.maps.Marker({
        position: LatLngGhana,
        map: this.map,
        draggable: true,
        visible: false,
      });

      // this.infowindow = new google.maps.InfoWindow();
      // this.infowindow.setContent(this.updateMarkerContent());
      // this.infowindow.open(this.map, this.marker);

      google.maps.event.addListener(this.marker, "dragend", (pointData) => {
        const latitude = pointData.latLng.lat();
        const longitude = pointData.latLng.lng();

        this.updateBusinessLocation({
          latitude,
          longitude,
        });
      });

      google.maps.event.addListener(this.map, "click", (pointData) => {
        const latitude = pointData.latLng.lat();
        const longitude = pointData.latLng.lng();

        this.updateBusinessLocation({
          latitude,
          longitude,
        });
      });
    },
    initMapForFacilityLocation() {
      const mapEl = document.getElementById("map");
      mapEl.classList.add("map-display");

      const LatLngGhana = new google.maps.LatLng(
        this.getValueFromSource(this.locationData, "latitude"),
        this.getValueFromSource(this.locationData, "longitude")
      );

      this.map = new google.maps.Map(mapEl, {
        center: LatLngGhana,
        zoom: 10,
      });

      this.marker = new google.maps.Marker({
        position: LatLngGhana,
        map: this.map,
        draggable: false,
        clickable: false,
        visible: true,
      });

      this.infowindow = new google.maps.InfoWindow();
      this.infowindow.setContent("<p>Your Business Location</p>");
      this.infowindow.open(this.map, this.marker);
    },
    filterOptions(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    updateMarkerContent(location = "") {
      return `
        <p style="font-weight: bold;">Your business location</p>
        <p>${location || "Ghana"}</p>
      `;
    },
    onSearchLocation(search) {
      try {
        this.mapLoading = true;

        this.locationsDataSource = [];

        if (!search) {
          return;
        }

        const service = new google.maps.places.PlacesService(this.map);
        const request = {
          query: search,
          // fields: ["formatted_address", "name", "geometry"],
          // type: ["establishment", "address", "geocode"],
          location: new google.maps.LatLng(
            this.country.latitude,
            this.country.longitude
          ),
        };

        service.textSearch(request, (results, status) => {
          this.mapLoading = false;
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.locationsDataSource = results;
          } else {
            this.locationsDataSource = [];
          }
        });
      } catch (error) {
        this.mapLoading = false;
        console.log("api error", error);
      }
    },
    onSelect(locationName) {
      const location = this.locationsDataSource.find(
        (loc) => loc.name === locationName
      );
      if (location) {
        const latitude = location.geometry.location.lat();
        const longitude = location.geometry.location.lng();

        this.updateBusinessLocation(
          {
            latitude,
            longitude,
          },
          true
        );
      }
    },
    updateBusinessLocation(latLng, centerMap = false) {
      this.$emit("update-business-location", latLng);

      if (!this.marker.getVisible()) {
        this.marker.setVisible(true);
      }

      const position = new google.maps.LatLng(
        latLng.latitude,
        latLng.longitude
      );

      this.marker.setPosition(position);

      if (centerMap) {
        this.map.setCenter(position);
        this.map.setZoom(20);
      }
    },
    findPlaceFromMapEvent(mapPointData) {
      /** to be continued in the future */

      const service = new google.maps.places.PlacesService(this.map);

      const location = new google.maps.LatLng(
        mapPointData.latLng.lat(),
        mapPointData.latLng.lng()
      );

      console.log("location", location);

      const request = {
        location,
        radius: 500,
      };

      const callback = (results, status) => {
        console.log("results", results);
        console.log("status", status);
        // if (status === google.maps.places.PlacesServiceStatus.OK) {
        //   console.log("results", cloneDeep(results));
        // } else {
        //   // this.locationsDataSource = [];
        // }
      };

      service.nearbySearch(request, callback);
    },
    async getCountryStates(geoId) {
      try {
        this.loading = true;

        await this.getGeoData({ geoId, type: "states" });
      } finally {
        this.loading = false;
      }
    },
    async getStateCities(geoId) {
      try {
        this.laoding = true;

        await this.getGeoData({ geoId, type: "cities" });
      } finally {
        this.loadign = false;
      }
    },
  },
};
</script>

<style>
.map-container {
  width: 100%;
  height: 400px;
}
.pac-container {
  z-index: 10000 !important;
}
.map-display {
  width: 100%;
  height: 400px;
}
</style>
