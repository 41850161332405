<template>
 <a-form-item label="Category"  >
    <a-select
      v-decorator="[
            'entity_type',
            {
            rules: [{ required: true, message: 'Category is required!' }],
            },
        ]"
      placeholder="Please select your category"
      :disabled="disabled"
    >
        <a-select-option :key="index"  v-for="(type,index) in entityTypes" :value="type.value">
          {{type.label}}
        </a-select-option>
    </a-select>
</a-form-item>
</template>

<script>

import ProfileMixin from '@/mixins/Profile'
import UserEntityTypes from "@/store/user/entity_types.js";
import {mapGetters} from "vuex"
export default {
  mixins:[ProfileMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
     ...mapGetters('auth',[ "currentUserType"]),
    entityTypes(){
      return UserEntityTypes[this.currentUserType]
    },
  },

}
</script>

<style>
form{
   text-transform: capitalize;
}
</style>