export default {
  tour_sight: [
    {
      label: "Natural Resource",
      value: "natural_resource"
    }, {
      label: "Man Made",
      value: "man_made"
    }, {
      label: "Historic",
      value: "historic"
    }
  ],
  food_and_drinks: [
    {
      label: "Restaurant",
      value: "restaurant"
    }, {
      label: "Chop Bar",
      value: "chop_bar"
    }, {
      label: "Drinking Spots",
      value: "drinking_spots"
    }, {
      label: "Contract Caterers",
      value: "contract_caterers"
    }, {
      label: "Conference",
      value: "conference"
    }, {
      label: "Fast Food",
      value: "fast_food"
    }
  ],
  hotel_and_accommodations: [
    {
      label: "5 - Star",
      value: "five_star_hotel"
    }, {
      label: "4 - Star",
      value: "four_star_hotel"
    }, {
      label: "3 - Star",
      value: "three_start_hotel"
    }, {
      label: "2 - Star",
      value: "two_star_hotel"
    }, {
      label: "1 - Star",
      value: "one_star_hotel"
    }, {
      label: "Budget Hotel",
      value: "budget_hotel"
    },
    {
      label: "Guest Hotel",
      value: "guest_house"
    },
    {
      label: "Apartments",
      value: "apartments"
    },
    {
      label: "Hostel",
      value: "hostel"
    }, {
      label: "Home Stay",
      value: "home_stay"
    }
  ],
  transport: [
    {
      label: "Air Vehicle",
      value: "air_vehicle"
    }, {
      label: "Water Vehicle",
      value: "water_vehicle"
    }, {
      label: "Land Vehicle",
      value: "land_vehicle"
    }
  ],
  events: [
    {
      label: "Live Events",
      value: "live_events"
    }, {
      label: "Sport Events",
      value: "sports_events"
    }, {
      label: "Cinema",
      value: "cinema"
    }, {
      label: "Clubbing",
      value: "clubbing_events"
    }
  ],
  tour_operator: [
    {
      label: "Travel & Tour Operations",
      value: "travel_and_tour"
    },
    {
      label: "Travel Agency",
      value: "travel"
    },
    {
      label: "Tour Operations",
      value: "tour"
    },
    {
      label: "Tour Guide",
      value: "tour_guide"
    }
  ]
}